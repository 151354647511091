/* eslint-disable max-len */
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CategorieList from '../../core/sysext/Boutique/containers/Produit/containers/Categorie/components/List';
import Backoffice from '../../core/sysext/CMS/containers/Backoffice';

const CategoriesPage: FC<PageProps> = props => {
  const { location } = props;

  return (
    <Backoffice location={location}>
      <CategorieList {...props} />
    </Backoffice>
  );
};

export default CategoriesPage;
